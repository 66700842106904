export interface Localized {
    profileText: string
}

export const LocalizedDict: {[key: string]: Localized} = {
    ja: {
        profileText: "Stack3はiOSとAndroidのアプリを制作しています。弊社が制作しているアプリは主にツールアプリです。",
    },
    en: {
        profileText: "Stack3 produces apps for iOS and Android, and our apps are mainly tool apps.",
    },
    zh_hans: {
        profileText: "Stack3为iOS和Android生成应用程序，我们的应用程序主要是工具应用程序。",
    },
    zh_hant: {
        profileText: "Stack3正在製作iOS和Android應用程序。我們正在生產的應用程序主要是工具應用程序。",
    },
    ko: {
        profileText: "Stack3은 iOS와 Android 용 앱을 생산하며, 우리 앱은 주로 툴 앱입니다.",
    }
}
