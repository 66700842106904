<script setup lang="ts">
import {getLocalizedAppInfoArray} from "~/mylib/AppInfo"
import {AppStoreCountries} from "~/mylib/AppStoreCountry";
import {LocalizedDict} from "~/mylib/Localized";
import {Consts} from "~/mylib/Consts";

const props = defineProps<{
  lang: string
}>()

const localized = LocalizedDict[props.lang]
const appStoreCountries = AppStoreCountries
const appInfoArray = getLocalizedAppInfoArray(props.lang)
const contactEmail = Consts.contactEmail
</script>

<template>
  <main class="container-fluid">
    <div class="post text-center">
      <h2 class="first">About Stack3</h2>
      <section>
        <p>{{localized.profileText}}</p>
        <p><a href="/">日本語</a> | <a href="/int/en">English</a> | <a href="/int/zh_hans">简体中文</a> | <a href="/int/zh_hant">繁體字</a> | <a href="/int/ko">한국</a></p>
      </section>

      <h2 class="mt-4">iOS</h2>
      <section>
        <p>App Store</p>
        <p>
          <span v-for="(appStoreCountry, index) in appStoreCountries" v-bind:key="index">
            <span v-if="index != 0"> | </span>
            <a :href="appStoreCountry.appStoreURL">{{appStoreCountry.countryName}}</a>
          </span>
        </p>
      </section>

      <h2 class="mt-4">Android</h2>
      <section>
        <a href="https://play.google.com/store/apps/developer?id=Stack3">Google Play Store</a>
      </section>

      <h2 class="mt-4">Contact for Apps</h2>
      <section>
        <p v-if="lang === 'ja'" class="small">携帯キャリアのメールアドレスは、迷惑メールフィルター設定によりこちらからのメール（{{contactEmail}}）が届かない可能性があります。</p>
        <table class="table mx-auto" style="">
          <tbody>
            <tr v-for="(appInfo, index) in appInfoArray" v-bind:key="index">
              <td class="text-right">{{appInfo.appName}}</td>
              <td class="align-middle">
                <a v-if="appInfo.contactEmailUrlForIOS"
                   :href="appInfo.contactEmailUrlForIOS">iOS</a>
                <a v-if="appInfo.contactEmailUrlForAndroid"
                   :href="appInfo.contactEmailUrlForAndroid"
                   class="ms-4">Android</a>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </main>
</template>
