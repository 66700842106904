export interface AppStoreCountry {
    countryName: string
    appStoreURL: string
}

export const AppStoreCountries: Array<AppStoreCountry> = [
    {
        countryName: "日本",
        appStoreURL: "https://itunes.apple.com/jp/developer/stack3/id375916874",
    },
    {
        countryName: "USA",
        appStoreURL: "https://itunes.apple.com/us/developer/stack3/id375916874",
    },
    {
        countryName: "中国",
        appStoreURL: "https://itunes.apple.com/cn/developer/stack3/id375916874",
    },
    {
        countryName: "香港",
        appStoreURL: "https://itunes.apple.com/hk/developer/stack3/id375916874",
    },
    {
        countryName: "台灣",
        appStoreURL: "https://itunes.apple.com/tw/developer/stack3/id375916874",
    },
    {
        countryName: "한국",
        appStoreURL: "https://itunes.apple.com/kr/developer/stack3/id375916874",
    }
]